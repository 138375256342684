//LOGO
export const logo = '/assets/svg/logo.svg'
export const png_logo = '/assets/images/logo.png'
export const logoHorizontal = '/assets/svg/logoHorizontal.svg'
export const logoHorizontalBlack = '/assets/svg/logoHorizontalBlack.svg'
export const logoHorizontalWhite = '/assets/svg/logoHorizontalWhite.svg'
export const siteName = 'Procurri'

//REGISTRATION ICONS
export const step1_icon = '/assets/images/step1.png'
export const step2_icon = '/assets/images/step2.png'
export const step3_icon = '/assets/images/step3.png'
export const step4_icon = '/assets/images/step4.png'
export const step5_icon = '/assets/images/step5.png'

class langObj {
    constructor(show, value) {
        this.show = show
        this.value = value
    }
    getLangObj() {
        return this
    }
}
export const languageArr = [
    new langObj('English', 'English'),
    new langObj('Mandarin', 'Mandarin'),
    new langObj('French', 'French'),
    new langObj('Spanish', 'Spanish'),
    new langObj('Portugese', 'Portugese'),
    new langObj('BrazilPortugese', 'BrazilPortugese'),
    new langObj('German', 'German'),
]
